import React, { Fragment } from 'react';
import styled from 'styled-components';
import FluidImage from '../../components/FluidImage'
import Caption from '../../components/Caption/index'

import { col, device } from '../../global/theme'
import { Column } from '../../global/grid'

const Grid = styled.div`
    margin-top: 3rem;
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);

    @media ${device.mobileL} {
        margin-top: 0;
        grid-gap: 3rem;
    }
`;

const TextBlockImage = ({ addImages, images }) => {
    //console.log(images);

    return (
        <Fragment>
            {addImages &&  ((
                <Column xsWidth={col.twelve} mdWidth={col.six}>
                    <Grid>
                        {images.map((single, index) => (
                            <Fragment>
                                <div>
                                    <FluidImage key={index} image={single.image}/>
                                    { single.image.caption && ((
                                        <Caption caption={single.image} />
                                    ))}
                                </div>
                            </Fragment>
                        ))}
                    </Grid>
                </Column>
            ))}
        </Fragment>
    );
}

export default TextBlockImage;
