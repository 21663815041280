import React, { Fragment } from 'react';

// Atoms
import { Card } from '../../atoms/card/Card'
import { Icon } from '../../atoms/icon/Icon';
import { Heading } from '../../atoms/typography/headings'
import { Button } from '../../atoms/button/Button';
// Theme
import { box, col, position } from '../../global/theme'
import { Column, SmallStack } from '../../global/grid'

const TextBlockCard = ({ addCard, cardLink, cardTitle }) => {
    return (
        <Fragment>
            {addCard && ((
                <Column alignSelf={position.center} gutters xsWidth={col.twelve} mdWidth={col.six} lgWidth={col.five} lgOffsetWidth={col.one} xsPaddingTop={box.halfGlobalPadding} smPaddingTop={box.globalPadding}>
                    <Card isTertiaryFilledCard={true}>
                        <SmallStack>
                            <Heading isH6={true}>{cardTitle}</Heading>
                            <Button href={cardLink.url} containsIcon={true} isDarkButton={true}>{cardLink.title}<Icon icon="chevronright" size="14px" /></Button>
                        </SmallStack>
                    </Card>
                </Column>
            ))}
        </Fragment>
    );
}

export default TextBlockCard;
