import React from 'react';
import styled from 'styled-components'
// Components
import FluidImage from '../../components/FluidImage';
// Atoms
import { Button } from '../../atoms/button/Button';
import { Icon } from '../../atoms/icon/Icon';
// Theme
import { easing } from '../../global/animations';
// import { Heading } from '../../atoms/typography/headings'


export const Overlay = styled.div`
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    right: 0;
    top: 0;
    background: ${({ theme }) => theme.tertiaryPrimary};
    border-radius: 50%;
    z-index: 9;
    transition: ${easing.theme};
`;

export const StyledIcon = styled(Icon)`
    position: absolute;
    width: 30px;
    height: 30px;
    left: 25%;
    bottom: 25%;
    z-index: 9;
    fill:  ${({ theme }) => theme.primaryTertiary};
`;

const StyledButton = styled(Button)`
  padding: 0;

  &:hover {
    ${Overlay} {
        width: 300px;
        height: 300px;
        right: -150px;
        top: -150px;
      }
    }
`;

const PageListItem = ({ item }) => {
    const image = item.featuredImage
    const url = '/' + item.uri
    // console.log(item)
    return (
        <StyledButton isPrimaryButton={true} key={item.id} href={url}>
            <Overlay>
                <StyledIcon icon="chevronright"/>
            </Overlay>
            {image && ((
                <FluidImage image={image}/>
            ))}
        </StyledButton>
    )
}

export default PageListItem