import styled from 'styled-components';
import { box, device } from '../../global/theme';
import { Icon } from '../../atoms/icon/Icon';
import { easing } from '../../global/animations';
import { Overlay } from '../../layouts/PageList/PageListItem';

export const GalleryGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: ${box.halfGlobalPadding};
    width: 100%;
    margin: 0 auto;

    @media ${device.tablet} {
        grid-template-columns: repeat(2, 1fr);
    }	

    @media ${device.laptop} {
        grid-template-columns: repeat(3, 1fr);
    }	
`;

export const ModalIcon = styled(Icon)`
    position: absolute;
    opacity: 0;
    z-index: 1;
    bottom: 25%;
    left: 25%;
    fill: ${({ theme }) => theme.primaryTertiary};
    transition: ${easing.theme};
`;

export const GalleryItem = styled.div`
    cursor: pointer;
    position: relative;
    overflow: hidden;

    .gatsby-image-wrapper {
        height: 400px;
        max-height: 400px;
    }

    &:hover {
        ${Overlay} {
            width: 300px;
            height: 300px;
            right: -150px;
            top: -150px;
        }

        ${ModalIcon} {
            opacity: 1;
        }
    }
`;

export const Modal = styled.div`
    position: fixed;
    z-index: 999999999999;
    width: 95%;
    max-width: 700px;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: ${easing.theme};
    
    @media ${device.tablet} {
        width: 80%;
    }	
    @media ${device.desktop} {
        width: 60%;
    }	

    img {
        width: 100%;
    }
`;

export const ModalOverlay = styled.div`
    position: fixed;
    z-index: 9999999;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    transition: ${easing.theme};
`;

export const ModalBody = styled.div`
    position: relative;
`;

export const GalleryArrowLeft = styled.button`
    position: absolute;
    left: -60px;
    top: 50%;
    transform: translate3d(0,-50%,0);
    background: transparent;
    border: none;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 50%;

    @media ${device.mobileL} {
      left: -100px;
    }
`;

export const GalleryArrowRight = styled.button`
    position: absolute;
    right: -60px;
    top: 50%;
    transform: translate3d(0,-50%,0);
    background: transparent;
    border: none;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 50%;

    @media ${device.mobileL} {
      right: -100px;
    }
`;

export const GalleryArrowIcon = styled(Icon)`
    fill: ${({ theme }) => theme.tertiaryPrimary};
`;

export const GalleryCloseIcon = styled(Icon)`
    fill: ${({ theme }) => theme.tertiaryPrimary};
`;

export const GalleryCloseButton = styled.button`
    position: absolute;
    right: 0;
    top: -60px;
    transform: translate3d(0, 0, 0);
    background: transparent;
    padding: 0;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 50%;
`;